<template>
  <div>
    <div class="pc">
      <div>
        <!-- header -->
        <div class="flex-between margin-bottom-16" v-if="vLogo">
          <div class="flex-align">
            <div class="talk-icon">
              <img src="/static/icon/u_comment-alt-dots.svg" alt="런치톡" class="svg-primary">
            </div>
            <div class="talk-title">런치톡</div>
          </div>
          <lp-check-bool :checkedVal.sync="req_email" @onCheck="onCheckEmail"
                         label="답변을 이메일 알림으로 받겠습니다."></lp-check-bool>
        </div>

        <!-- content -->
        <div class="content-wrapper">
          <div class="content" ref="pc_content">
            <template v-for="(item,idx) in list">
              <div :style="itemStyle(idx)" :key="'msg-'+idx">
                <!-- 유저 작성 -->
                <div v-if="item.user" class="message-from">
                  <div style="margin-right:4px;text-align:right">
                    <img src="/static/icon/u_trash-alt.svg" class="svg-sub3 trash" @click="clickTrash(idx)">
                    <span class="body6 sub3" style="flex:1">{{ datesFormat(item.created_time, 'time_2') }}</span>
                  </div>
                  <talk-images v-if="item.imgs && item.imgs.length>0" :images="item.imgs"></talk-images>
                  <div v-else class="message">
                    <talk-file v-if="item.file" :item="item"></talk-file>
                    <template v-else>{{ item.message }}</template>
                  </div>
                </div>
                <!-- 관리자 답변 -->
                <div v-else>
                  <div class="flex-align" style="margin-bottom:8px"
                       v-if="idx === 0 || (idx>0 && list[idx-1].user)">
                    <div class="lp-logo">
                      <img src="/static/img/ic_logo.svg">
                    </div>
                    <div class="subtitle6 sub2" style="margin-left: 8px">런치팩 {{ item.admin_name }}</div>
                  </div>
                  <div class="message-to">
                    <div>
                      <talk-images v-if="item.imgs && item.imgs.length>0" :images="item.imgs"></talk-images>
                      <div v-else class="message">
                        <talk-file v-if="item.file" :item="item"></talk-file>
                        <template v-else>{{ item.message }}</template>
                      </div>
                    </div>
                    <div class="body6 sub3" style="margin-left:4px">{{ datesFormat(item.created_time, 'time_2') }}</div>
                  </div>
                </div>
              </div>

              <div class="flex-align" style="margin:16px 0"
                   :key="'date-'+idx" v-if="idx === list.length -1 || idx < list.length-2 && !compareDate(item, list[idx+1])">
                <div class="divider-date"></div>
                <div class="txt-date">{{ datesFormat(item.created_time, 'date_8') }}</div>
                <div class="divider-date"></div>
              </div>
            </template>
          </div>
        </div>

        <!-- 파일 전송 -->
        <div style="margin-bottom:12px" v-if="enableChat">
          <input type="file" id="file-multi-uploader"
                 @change="onFileChange" multiple
                 accept="image/gif, image/jpeg, image/png, .docx, .pptx, .xlsx, .hwp, .pdf">
            <div class="flex-align">
              <label for="file-multi-uploader">
                <div class="button is-gray flex-align unselect" style="width:100px;height:40px">
                  <img src="/static/icon/u_file-upload.svg" class="svg-sub3">
                  <span class="body4-medium sub3" style="margin-left:4px">파일 첨부</span>
                </div>
              </label>
              <div class="body6 sub3" style="margin-left:8px">형식) jpg, gif, png, hwp, pdf, pptx, docx, xlsx (최대 5개, 10MB까지 등록)
              </div>
            </div>
        </div>

        <!-- 메시지 전송 -->
        <template v-if="enableChat">
          <div class="box-send">
            <textarea v-model="sendData.message" @keyup="e => sendData.message = e.target.value" rows="6"></textarea>
            <button class="button is-primary" style="width:100%" @click="clickSend">전송하기</button>
          </div>
          <div class="body5-medium sub3" style="margin-top:6px;text-align: right">Ctrl + Enter로 바로 전송</div>
        </template>
      </div>

      <div class="spinner" v-if="vSpinner">
        <img src="/static/icon/spinner.gif" alt="스피너">
      </div>
    </div>
    <div class="mobile">
      <!-- content -->
      <div ref="mobile_content" class="content">
        <template v-for="(item,idx) in list">
          <div :style="itemStyle(idx)" :key="'msg-m-'+idx">
            <!-- 유저 작성 -->
            <div v-if="item.user" class="message-from">
              <div style="margin-right:4px;text-align:right">
                <img src="/static/icon/u_trash-alt.svg" class="svg-sub3 trash" @click="clickTrash(idx)">
                <span class="body8 sub3" style="flex:1">{{ datesFormat(item.created_time, 'time_2') }}</span>
              </div>
              <talk-images v-if="item.imgs && item.imgs.length>0" :images="item.imgs"></talk-images>
              <div v-else class="message">
                <talk-file v-if="item.file" :item="item"></talk-file>
                <template v-else>{{ item.message }}</template>
              </div>
            </div>

            <!-- 관리자 답변 -->
            <div v-else>
              <div class="flex-align" style="margin-bottom:8px"
                   v-if="idx === 0 || (idx>0 && list[idx-1].user)">
                <div class="lp-logo">
                  <img src="/static/img/ic_logo.svg">
                </div>
                <div class="body6-medium sub2" style="margin-left: 8px">런치팩 {{ item.admin_name }}</div>
              </div>
              <div class="message-to">
                <div>
                  <talk-images v-if="item.imgs && item.imgs.length>0" :images="item.imgs"></talk-images>
                  <div v-else class="message">
                    <talk-file v-if="item.file" :item="item"></talk-file>
                    <template v-else>{{ item.message }}</template>
                  </div>
                </div>
                <div class="body8 sub3" style="margin-left:4px">{{ datesFormat(item.created_time, 'time_2') }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex-align" style="margin:16px 0"
               :key="'date-'+idx" v-if="idx === list.length -1 || idx < list.length-2 && !compareDate(item, list[idx+1])">
            <div class="divider-date"></div>
            <div class="txt-date">{{ datesFormat(item.created_time, 'date_3') }}</div>
            <div class="divider-date"></div>
          </div>
        </template>
      </div>

      <!-- 전송 -->
      <div class="box-mobile-send" v-if="enableChat">
        <input type="file" id="file-multi-uploader-mobile"
               @change="onFileChange" multiple
               accept="image/gif, image/jpeg, image/png, .docx, .pptx, .xlsx, .hwp, .pdf">
        <label for="file-multi-uploader-mobile">
          <img src="/static/icon/u_file-upload.svg" class="ic-upload svg-sub3">
        </label>
        <div contenteditable="true" id="input-send" ref="send"></div>
        <img src="/static/icon/fi_arrow-up-circle.svg" class="ic-send svg-primary" @click="clickSendMobile">
      </div>
      <div class="spinner" v-if="vSpinner">
        <img src="/static/icon/spinner.gif" alt="스피너">
      </div>
    </div>

    <div class="del-modal">
      <sweet-modal overlay-theme="dark" ref="delModal" width="564px"
                   :hide-close-button="true">
        <div class="subtitle4">메세지를 삭제하시면 복구되지 않습니다.<br>정말로 삭제하시겠습니까?</div>
        <div class="buttons flex-between body5-medium">
          <button class="button body2-bold" style="width:50%;height:52px" @click="$refs.delModal.close()">취소</button>
          <button class="button is-primary margin-left-16 body2-bold"
                  style="width:50%;height:52px;"
                  @click="clickDelete">확인
          </button>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>

<script>
  import {SweetModal} from 'sweet-modal-vue'
  import LpCheckBool from "../module/LpCheckBool";
  import TalkImages from "./TalkImages";
  import TalkFile from "./TalkFile";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  const io = require('socket.io-client');

  export default {
    name: "LaunchTalk",
    mixins: [
      UserAPIMixin
    ],
    components: {
      TalkFile,
      TalkImages,
      LpCheckBool,
      SweetModal
    },
    props: {
      vLogo: {
        type: Boolean,
        default: true
      },
      enableChat: {
        type: Boolean,
        default: true
      },
      talkId: {
        type: Number,
        default: 0
      },
      talks: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },
    created() {
      this.getTalk();
      this.getData();
      this.setSocketIO();
      window.addEventListener('keydown', this.keydownFunc);
      window.addEventListener('keyup', this.keyupFunc);
    },
    destroyed() {
      window.removeEventListener('keydown', this.keydownFunc);
      window.removeEventListener('keyup', this.keyupFunc);
    },
    mounted() {
      /*this.$nextTick(() => {
        setTimeout(() => {
          document.getElementById('ch-plugin').style.display = 'none';
        }, 1000);
      });*/
    },
    beforeDestroy() {
      //document.getElementById('ch-plugin').style.display = '';
      this.socket.disconnect();
    },
    data() {
      return {
        keydownCtrl: false,
        socket: undefined,
        files: [],
        fileLength: 0,
        req_email: false,
        selectedItem: -1,
        list: [],
        sendData: {
          message: '',
          files: []
        },
        cur_page: 1,
        total_page: 1,
        loading: false,
        vSpinner: false
      }
    },
    methods: {
      keyupFunc(e) {
        let key = e.key;

        // Ctrl키, Cmd키
        if (key === 'Control' ||
          key === 'Meta') {
          this.keydownCtrl = false;
        }
      },
      keydownFunc(e) {
        let keyCode = e.keyCode;
        let key = e.key;

        // Ctrl키, Cmd키
        if (key === 'Control' ||
          key === 'Meta') {
          this.keydownCtrl = true;
        }

        // 메시지 전송 (ctrl + enter)
        if(keyCode === 13 && this.keydownCtrl) {
          this.clickSend();
          e.preventDefault();
        }
      },
      setSocketIO() {
        this.socket = io.connect('https://master-dev.launchpack.co.kr:3000');
        setTimeout(()=> {
          this.socket.on('message',(msg) => {
            if(msg.type === 'launch_talk' && msg.talk === this.talkId && msg.admin) {
              this.list.splice(0,0, ...msg.msg);
              this.setRead();
            }
          });
        },500);
      },
      onCheckEmail(res) {
        this.request.user.patch(`launchpack/talk/${this.talkId}`, { req_email: res });
        this.$emit('onCheckEmail', res);
      },
      compareDate(cur, prev) {
        const curDate = cur.created_time.split(' ')[0];
        const prevDate = prev.created_time.split(' ')[0];
        return curDate === prevDate;
      },
      clickSend() {
        this.send();
      },
      clickSendMobile() {
        this.sendData.message = this.$refs.send.innerText;
        this.send();
      },
      send() {
        if(this.sendData.message.trim()==='') {
          return;
        }
        const params = {
          message: this.sendData.message
        };
        this.request.user.post(`launchpack/talk/${this.talkId}/message`, params).then(res => {
          if (res.status === 200) {
            this.list.splice(0,0,{
              user: this.user.user_id,
              admin: null,
              message: this.sendData.message,
              created_time: this.dayjs().format('YYYY-MM-DD HH:mm:ss')
            })
            this.sendData.message = '';
            this.$refs.send.innerText = '';
          }
        })
      },
      clickTrash(item) {
        this.selectedItem = item;
        this.$refs.delModal.open();
      },
      initScrollEvent() {
        this.$nextTick(() => {
          this.$refs.pc_content.addEventListener('scroll', this.onScroll);
          this.$refs.mobile_content.addEventListener('scroll', this.onScroll);
        });
      },
      onScroll(e) {
        if (e.target.scrollTop < 100) {
          this.getData();
        }
      },
      getTalk() {
        this.request.user.get(`launchpack/talk/${this.talkId}`).then(res=>{
          this.req_email = res.data.req_email;
          this.$emit('onCheckEmail', this.req_email);
        });
      },
      getData() {
        if (this.loading) return;

        if (this.cur_page <= this.total_page) {
          this.loading = true;
          this.request.user.get(`launchpack/talk/${this.talkId}/message?ordering=-created_time&page_num=${this.cur_page}&page_length=50`).then(res => {
            this.loading = false;
            this.total_page = res.data.total_page;
            this.list = this.list.concat(res.data.data);
            this.setRead();
            if (this.cur_page === 1) {
              this.initScrollEvent();
            }
            this.cur_page++;
          }).catch(() => {
            this.loading = false;
          });
        }
      },
      // 읽음 처리
      setRead() {
        let ids = this.list.filter(item => { return !item.read && item.admin !== null }).map(item => { return item.id });
        if(ids.length>0) {
          this.request.user.post(`launchpack/talk/${this.talkId}/message/read`, {ids:ids}).then(res=>{
            if(res.status === 200) {
              this.list.filter(item => { return ids.indexOf(item.id)>-1 }).forEach(item => { item.read = true; });
            }
          });
        }
      },
      clickDelete() {
        this.request.user.delete(`launchpack/talk/${this.talkId}/message/${this.list[this.selectedItem].id}`).then(res => {
          if (res.status === 200) {
            this.list.remove(this.selectedItem);
          }
        });
        this.$refs.delModal.close();
      },
      itemStyle(idx) {
        let deco = {};
        if (idx > 0 && this.list[idx - 1].author !== this.list[idx].author) {
          deco.marginTop = '32px';
        }
        return deco;
      },
      onFileChange(e) {
        this.fileLength = e.target.files.length;
        if (this.fileLength > 5) {
          this.toast('파일을 최대 5개 이하로 선택해주세요');
          return;
        }

        e.target.files.forEach(file => {

          let reader = new FileReader();
          reader.onload = (f) => {
            let ext = '';
            let files = file.name.split('.');
            if (files.length > 1) {
              ext = files[files.length - 1];
            }
            if (this.files.filter(item => {
              return item.name === file.name && item.size === file.size
            }).length === 0) {
              this.files.push({
                name: file.name,
                file: f.target.result,
                size: file.size,
                extenstion: ext
              })
            }
          };
          reader.readAsDataURL(file);
        });
      },
      async uploadFiles() {
        const extImgs = ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'];

        let arr = [
          {
            imgs: this.files.filter(i => {return extImgs.indexOf(i.extenstion) > -1}).map(i => { return i.file })
          },
          {
            file: this.files.filter(i => {return extImgs.indexOf(i.extenstion) === -1}).map(i => { return i.file })
          }
        ];
        this.vSpinner = true;
        for (let params of arr) {
          if(params[Object.keys(params)[0]].length>0) {
            try {
              let res = await this.request.user.post(`launchpack/talk/${this.talkId}/message`, params);
              this.list.splice(0,0, ...res.data.msgs);
              this.setRead();
            } catch {
              console.log('err')
            }
          }
        }
        this.vSpinner = false;
        this.fileLength = 0;
        this.files = [];
      }
    },
    watch: {
      files(n) {
        if (this.fileLength > 0 && n.length === this.fileLength) {
          this.uploadFiles();
        }
      },
      list(n) {
        this.$emit('update:talks', n);
      },
      talks(n) {
        if(n.length !== this.list.length) {
          this.list = this.talks;
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .talk-icon
    background-color $primary-light2
    width 32px
    height 32px
    display flex
    justify-content center
    align-items center
    border-radius 100%

    img
      width 18px
      height 18px

  .talk-title
    color $main
    font-size 24px
    font-weight 500
    margin-left 8px

  .content-wrapper
    border-radius 8px
    margin-bottom 12px
    overflow hidden

  .content
    border 1px solid $gray2
    padding 24px
    background-color $gray2
    height 420px
    overflow-y auto
    display flex
    flex-direction column-reverse

  .message-from
  .message-to
    display flex
    align-items flex-end
    margin 8px 0

  .message-from
    justify-content flex-end

  .message
    font-size 14px
    padding 10px 12px
    border-radius 8px
    background-color white
    max-width 480px
    color $main
    white-space pre-line

  .lp-logo
    background-color $sub
    border-radius 100%
    width 24px
    height 24px
    display flex
    align-items center
    justify-content center

    img
      width 10px
      height 15px

  textarea
    border 0
    padding 12px 16px
    border-radius 8px
    width 100% !important
    resize none

  input[type=file]
    position absolute
    width 0
    height 0
    border 0 !important

  textarea:focus
    border 0

  .box-send
    border-radius 8px
    border 1px solid $gray1
    overflow hidden

    .button
      border-top-left-radius 0
      border-top-right-radius 0

  .trash
    width 18px
    height 18px
    vertical-align sub
    margin-right 4px

  .trash:hover
    filter invert(54%) sepia(46%) saturate(5884%) hue-rotate(360deg) brightness(100%) contrast(110%)
    cursor pointer

  .txt-date
    font-size 14px
    font-weight 400
    text-align center
    padding 0 20px
    color $sub3

  .divider-date
    flex 1
    height 1px
    background-color $sub5

  .spinner
    position fixed
    top 50%
    left 50%
    transform translate(-50%, -50%)
    background rgba(0,0,0,0.7)
    display flex
    align-items center
    justify-content center
    border-radius 12px

  .pc
    .spinner
      width 146px
      height 146px
  .mobile
    .spinner
      width 78px
      height 78px
      img
        width 34px
        height 34px


  @media (max-width: 1024px)
    .content
      border 0
      padding 24px 24px 60px 24px
      background-color $gray2
      margin-top 60px
      height calc(100vh - 120px)
      overflow-y auto

    .message
      max-width 232px

    .box-mobile-send
      position fixed
      bottom 0
      left 0
      background-color white
      border-top 1px solid $gray1
      width 100%
      min-height 60px
      display flex
      align-items center
      justify-content space-between
      padding 0 16px

    #input-send
      padding 8px 16px
      flex 1
      max-height 120px
      overflow-y auto

    #input-send:focus
      outline 0

    .txt-date
      font-size 10px

    .trash
      width 14px
      height 14px

</style>

<style lang="stylus">
  .del-modal .sweet-modal.is-mobile-fullscreen
    top 50% !important
    left 50% !important
    transform translate(-50%, -50%) !important

  .del-modal .sweet-content
    padding-bottom 12px !important

  @media (min-width: 501px)
    .del-modal .sweet-modal
      height 280px

    .del-modal .sweet-content
      padding-top 72px !important

    .buttons
      padding 64px 16px 0 !important

  @media (max-width: 500px)
    .del-modal .sweet-modal
      max-width 80vw !important
      height 184px

      .subtitle4
        font-size 14px

      .body2-bold
        font-size 13px

      .button
        height 36px !important

    .del-modal .sweet-content
      padding-top 38px !important

    .buttons
      padding 38px 16px 0 !important
</style>
